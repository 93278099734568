import NotFoundPage from '@/modules/common/pages/not-found-page';

export async function getStaticProps() {
  return {
    props: {
      bodyClassName: 'login-page',
    },
  };
}

export default NotFoundPage;
