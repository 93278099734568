import AnonymousLayout from '@/layouts/anonymous';

const ComingSoon = () => (
  <div className="h-100 w-100">
    <h1 className="text-center">Not Found</h1>
  </div>
);

const ComingSoonPage = () => <AnonymousLayout Component={ComingSoon} />;

export default ComingSoonPage;
